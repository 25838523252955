/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
export function postSubtitle(profile) {
  // const subtitleCategory = profile.wp_categories ? profile.wp_categories : '';
  const subtitleAuthor = profile.wp_author ? (' by ' + profile.wp_author) : '';
  const subtitleReadingTime = profile.c_estimatedReadingTime ? (' - Reading Time: ' + profile.c_estimatedReadingTime) : '';
  return subtitleAuthor + subtitleReadingTime;
}